<template>
	<div class="num-img-main flex flex-col-center flex-row-around">
		<div class="charts-one">
			<div id="szhjc" style="width: 380px;height: 400px;"></div>
		</div>
		<div class="charts-one">
			<div id="szhjy" style="width: 380px;height: 400px;"></div>
		</div>
		<div class="charts-one">
			<div id="szgl" style="width: 380px;height: 400px;"></div>
		</div>
		<div class="charts-one">
			<div id="szhcx" style="width: 380px;height: 400px;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'num-img-item',
		props: {
			msg: {
				type: Object,
				default: {}
			}
		},
		data(){
			return{
				
			}
		},
		mounted() {
			
		},
		methods:{
			initData(data) {
				this.getBasis(data.foundation);
				this.getOperate(data.business);
				this.getAdmin(data.manage);
				this.getEffect(data.effect);
			},
			//画图
			getCanvas(charts, data, color, optColor) {
				let option = {
					title: {
					  text: data.title
					},
					color: [color],
					tooltip: {
            trigger: "item",
            backgroundColor: "rgb(255,255,255)",
            borderColor: 'rgb(255,255,255)',
            extraCssText: 'box-shadow: 0 0 15px rgba(0,0,0,0.15);border-radius: 4px;',
            textStyle: {
              color: "#23282d", // 设置字体颜色
              fontSize: 12, // 设置字体大小
            },
            formatter: function(params) {
              var result = '<p>'+ params.name +'</p>';
              params.value.forEach(function(val, index) {
                var circleColor = color;
                // 自定义 tooltip 的内容，包括小圆球的颜色和数据项的名称及其值
                result += '<p style="margin-top: 7px">' + '<span style="display:inline-block;margin-right:5px;border-radius:8px;width:8px;height:8px;background-color:' + circleColor + ';"></span>' + '<span style="color: #3c4248;margin-right: 10px">' + data.indicator[index].name + '</span>' + '<span style="color: '+circleColor+';font-family: DINR;font-weight: 500">' + val + '</span>' + '</p>';
              });
              return result;
            }
					},
					radar: {
            name: {
              textStyle: {
                color: '#23282d',
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgb(228,231,233)",
                opacity: 1,
                width: 1,
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: "rgb(228,231,233)",
                opacity: 0,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(228,231,233,1)",
                type: "solid",
              },
            },
					    indicator: data.indicator
					},
					series: [
					    {
							type: 'radar',
							symbol: "circle",
							tooltip: {
							    trigger: 'item'
							},
                itemStyle: {
                  normal: {
                    borderColor: color,
                    borderWidth: 0.8,
                    color:'rgb(255,255,255)'
                  },
                },
                lineStyle: {
                  normal: {
                    color: color,
                    width: 2,
                  },
                },
                areaStyle: {
                  normal: {
                    color: color,
                    opacity: 0.2,
                  },
                },
							data: [
								{
									value: data.value,
									name: data.name,
									symbol: 'circle',
									areaStyle: {
										color: optColor
									}
								}
							]
					    }
					]
				};
				charts.setOption(option);
			},
			//数字化基础
			getBasis(event) {
				let nameList = [];
				let valueList = [];
				for (let i = 0; i < event.length; i++) {
					let nameData = {name: event[i].name, max: parseFloat(event[i].max)};
					nameList.push(nameData);
					valueList.push(event[i].value);
				}
				var myChart = this.$echarts.init(document.getElementById('szhjc'));
				let data = {
					indicator: nameList,
					value: valueList,
					name: '设备系统',
					title: '数字化基础'
				}
				this.getCanvas(myChart, data, '#FD5451', 'rgba(253, 84, 81, 0.2)');
			},
			//数字化经营
			getOperate(event) {
				let nameList = [];
				let valueList = [];
				for (let i = 0; i < event.length; i++) {
					let nameData = {name: event[i].name, max: parseFloat(event[i].max) };
					nameList.push(nameData);
					valueList.push(event[i].value);
				}
				var myChart = this.$echarts.init(document.getElementById('szhjy'));
				let data = {
					indicator: nameList,
					value: valueList,
					name: '研发设计',
					title: '数字化经营'
				}
				this.getCanvas(myChart, data, '#5B7CFD', 'rgba(57,132,245,0.2)');
			},
			//数字化管理
			getAdmin(event) {
				let nameList = [];
				let valueList = [];
				for (let i = 0; i < event.length; i++) {
					let nameData = {name: event[i].name, max: parseFloat(event[i].max)};
					nameList.push(nameData);
					valueList.push(event[i].value);
				}
				var myChart = this.$echarts.init(document.getElementById('szgl'));
				let data = {
					indicator: nameList,
					value: valueList,
					name: '经营战略',
					title: '数字化管理'
				}
				this.getCanvas(myChart, data, '#04CD83', 'rgba(4,205,131,0.2)');
			},
			//数字化成效
			getEffect(event) {
				let nameList = [];
				let valueList = [];
				for (let i = 0; i < event.length; i++) {
					let nameData = {name: event[i].name, max: parseFloat(event[i].max)};
					nameList.push(nameData);
					valueList.push(event[i].value);
				}
				var myChart = this.$echarts.init(document.getElementById('szhcx'));
				let data = {
					indicator: nameList,
					value: valueList,
					name: '产品质量',
					title: '数字化成效'
				}
				this.getCanvas(myChart, data, '#8661FE', 'rgba(134,97,254,0.2)');
			},
			
		},
		watch: {
			msg(newValue, value) {
				this.initData(newValue);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.num-img-main{margin-top: 20px;
		.charts-one{width: 394px;height: 400px;background: #FAFCFE;box-sizing: border-box;padding-top: 18px;}
	}
	
</style>