<template>
	<div class="basic-info-main">
		<div class="title-coantent flex flex-col-center flex-row-between">
			<span class="title-text">基本信息</span>
			<div class="line-title"></div>
		</div>
		<div class="table-content flex flex-wrap">
			<div class="flex flex-col-center" v-for="(item, index) in msg" :key="item.id">
				<span class="tab-title-text">{{item.title}}</span>
				<span class="con-text flex flex-col-center flex-row-between">
					<span>{{item.content?item.content:'--'}}</span>
					<!-- <span class="outh-btn" v-if="item.title=='认证状态'">查看认证信息</span> -->
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	export default{
		name: 'basic-info-item',
		props: {
			msg: {
				type: Array,
				default: []
			}
		},
		data(){
			return{
				
			}
		},
		mounted() {
			
		},
		methods:{
			initData(id) {
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.basic-info-main{background-color: #fff;padding: 30px;
		.title-coantent{
			.title-text{position: relative;font-size: 18px;color: #242D33;}
			.title-text::before{position: absolute;content: '';left: -30px;top: 5px;background: #3984F5;width: 4px;height: 20px;}
			.line-title{flex: 1;height: 1px;background: #EDF1F4;margin-left: 10px;}
		}
		.table-content{margin-top: 24px;
			.tab-title-text{width: 120px;height: 44px;background: #F6F7F9;border: 1px solid #E1E8EC;text-align: center;line-height: 44px;font-size: 12px;color: #444F57;box-sizing: border-box;}
			.con-text{width: 412px;height: 44px;padding: 0 20px;border: 1px solid #E1E8EC;line-height: 44px;font-size: 12px;color: #242D33;box-sizing: border-box;}
			.outh-btn{color: #3984F5;
				&:hover{cursor: pointer; text-decoration: underline;}
			}
		}
		
	}
	
</style>