<template>
	<div class="company-main">
		<basic-info-item :msg="allData"></basic-info-item>
		<number-portrait-item :msg="infoData"></number-portrait-item>
		<visit-situation-item :companyType="infoData.dictionary_type"></visit-situation-item>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	import basicInfoItem from "@/views/companyImg/components/basic-info-item.vue"
	import numberPortraitItem from "@/views/companyImg/components/number-portrait-item.vue"
	import visitSituationItem from "@/views/companyImg/components/visit-situation-item.vue"
	export default{
		components: {
			basicInfoItem, numberPortraitItem, visitSituationItem
		},
		data(){
			return{
				allData:[
					{title: '企业名称', content: ''}, {title: '企业编号', content: ''}, {title: '用户名', content: ''}, 
					{title: '自然客户id', content: ''}, {title: '统一社会信用代码', content: ''}, {title: '企业类型', content: ''}, 
					{title: '经济类型', content: ''},  {title: '机构类型', content: ''}, {title: '联系人', content: ''}, 
					{title: '联系电话', content: ''}, {title: '所在地区', content: ''}, {title: '详细地址', content: ''}, 
					{title: '所属网格', content: ''}, {title: '所属行业', content: ''}, {title: '是否为龙头企业', content: ''}, 
					{title: '人员规模', content: ''}, {title: '主营业务', content: ''}, {title: '注册时间', content: ''}, 
					{title: '企业来源', content: ''}, {title: '商企经理', content: ''}, {title: '问诊状态', content: ''}, {title: '认证状态', content: ''}, 
				],//基本信息
				infoData: {},//评分
			}
		},
		mounted() {
			let id = this.$route.query.id;
			this.initData(id);
		},
		methods:{
			initData(id) {
				this.$api.companyInfo({company_id: id}).then(res=>{
					this.infoData = res.data;
					this.allData[0].content = res.data.company;
					this.allData[1].content = res.data.company_no;
					this.allData[2].content = res.data.username;
					this.allData[3].content = res.data.three_id;
					this.allData[4].content = res.data.identification;
					this.allData[5].content = res.data.type_name;
					this.allData[6].content = res.data.economics_name;
					this.allData[7].content = res.data.institutional_name;
					this.allData[8].content = res.data.truename;
					this.allData[9].content = res.data.mobile;
					this.allData[10].content = res.data.areaname;
					this.allData[11].content = res.data.address;
					this.allData[12].content = res.data.grid_name;
					this.allData[13].content = res.data.dictionary_type==13?'制造业':(res.data.dictionary_type==14?'服务行业':'其他行业');
					this.allData[14].content = res.data.is_leader==1?'否':'是';
					this.allData[15].content = res.data.staff_size;
					this.allData[16].content = res.data.business;
					this.allData[17].content = res.data.addtime;
					this.allData[18].content = res.data.source==1?'自主注册':'问诊导入';
					this.allData[19].content = res.data.business_manage_name?res.data.business_manage_name:'--';
					this.allData[20].content = res.data.diagnose_status==1?'待问诊':(res.data.diagnose_status==2?'待诊断':'已诊断');
					let stutas = '未认证';
					if (res.data.vaildate_status==0) stutas = '未认证';
					if (res.data.vaildate_status==1) stutas = '审核中';
					if (res.data.vaildate_status==2) stutas = '已认证';
					if (res.data.vaildate_status==3) stutas = '认证失败';
					this.allData[21].content = stutas;
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.company-main{padding: 18px;height: 100%;box-sizing: border-box;overflow: scroll;scrollbar-width: none;-ms-overflow-style: none;
		
	}
	
</style>