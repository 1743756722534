<template>
	<div class="situation-main">
		<div class="title-coantent flex flex-col-center flex-row-between">
			<span class="title-text">问诊情况</span>
			<div class="line-title"></div>
		</div>
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane :label="item.title" :name="item.value" v-for="(item, index) in btnFunList" :key="index"></el-tab-pane>
		</el-tabs>
		<div v-loading="loading">
      <el-scrollbar ref="scrollbar" class="quest-scroll">
        <div class="yw-sys-content" v-if="allData.length>0">
          <div class="ti-list-content" v-for="(item, index) in allData" :key="item.id">
            <div class="sys-title-text">{{item.title}}</div>
            <div class="ti-content" v-for="(items, indexs) in item.profileLists" :key="items.id">
              <div class="ti-title-text">
                <span class="type-text">({{items.type==0?'单选':'多选'}})</span>{{' '+items.question_full}}
              </div>
              <div class="dx-content" v-if="items.type==0">
                <div class="radio-content flex flex-col-center" v-for="(dxItem, dxIndex) in items.answer" :key="dxItem.id">
                  <img class="radio-img" :src="radioImg[dxItem.is_check]" alt="" />
                  <span class="full-text">{{dxItem.full}}</span>
                </div>
              </div>
              <div class="dx-content" v-if="items.type==1">
                <div class="radio-content flex flex-col-center" v-for="(dxItem, dxIndex) in items.answer" :key="dxItem.id">
                  <img class="radio-img" :src="radioImg[dxItem.is_check]" alt="" />
                  <span class="full-text">{{dxItem.full}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="yw-sys-content" v-if="mobelData.length>0">
          <div class="ti-list-content" v-for="(item, index) in mobelData" :key="item.id">
            <div class="sys-title-text">{{item.title}}</div>
            <div class="ti-content" v-for="(items, indexs) in item.profileLists" :key="items.id">
              <div class="ti-title-text">
                <span class="type-text">({{getTypeText(items.type)}})</span>{{' '+items.question_full}}
              </div>
              <div class="dx-content" v-if="items.type==0 || items.type==1">
                <div class="radio-content flex flex-col-center" v-for="(dxItem, dxIndex) in items.answer" :key="dxItem.id">
                  <img class="radio-img" :src="radioImg[dxItem.is_check]" alt="" />
                  <span class="full-text">{{dxItem.full}}</span>
                </div>
              </div>
              <div class="dx-content" v-if="items.type==2">
                <input class="d-input-text" type="text" placeholder="选项" />
              </div>
              <div class="dx-content" v-if="items.type==3">
                <input class="d-input-text" type="text" placeholder="选项" v-for="(dxItem, dxIndex) in items.answer" :key="dxItem.id" />
              </div>
              <div class="dx-content" v-if="items.type==4">
                <div class="" v-for="(dxItem, dxIndex) in items.answer" :key="dxItem.id" style="margin-top: 10px;">
                  <div class="flex flex-col-center">
                    <img class="radio-img" :src="radioImg[dxItem.is_check]" alt="" />
                    <span class="full-text">{{dxItem.full}}</span>
                  </div>
                  <input class="d-input-text" type="text" placeholder="选项" style="display: block;" v-if="dxItem.is_check==1" />
                </div>
              </div>
              <div class="dx-content" v-if="items.type==5">
                <div class="flex flex-col-center" style="margin-top: 10px;">
                  <el-cascader :options="options" placeholder="请选择省市区" :props="{value: 'id', label: 'name' }"></el-cascader>
                  <input class="d-input-text" type="text" placeholder="详细地址" style="margin: 0 0 0 10px;" />
                </div>
              </div>
              <div class="dx-content" v-if="items.type==6">
                <el-date-picker style="width: 400px;height: 34px;margin-top: 10px;"  type="date" placeholder="选择日期"></el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
		<div class="look-btn-content flex flex-col-center">
			<button class="btn-text look-btn" @click="getZhendClick">查看诊断报告</button>
			<button class="btn-text exit-btn" @click="exitClick">返回</button>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'visit-situation-item',
		props: {
			companyType: {
				/*type: String,
				default: ''*/
			}
		},
		data(){
			return{
        loading:false,
				activeName: 'jc',//标签
				activeIndex: 0,//按钮下标
				btnFunList: [{title: '数字化基础', value: 'jc'},{title: '数字化经营', value: 'jy'},{title: '数字化管理', value: 'gl'},{title: '数字化成效', value: 'cx'}],//按钮数据
				disType: '',//
				radioImg: [require('@/assets/sendQuestionnaire/img_Radio.png'), require('@/assets/sendQuestionnaire/img_Radio_Selected.png')],//单选图片
				allData: [],//所有数据
				mobelData: [],//其他业务数据
				authList: [],//其他业务
				options: [],//地区数据
			}
		},
		mounted() {
			this.getAuthBtn();
		},
		methods:{
      resetScroll() {
        // 将滚动条位置设置为顶部
        const scrollContainer = this.$refs.scrollbar.wrap;
        scrollContainer.scrollTop = 0;
      },
			initData() {
				let data = {
					company_id: this.$route.query.id,
					dictionary_type: this.disType,
					target: Number(this.activeIndex)+1+''
				}
        this.loading = true
				this.$api.companyProfile(data).then(res=>{
					this.allData = res.data;
          this.loading = false
				})
			},
			//获取诊断报告
			getZhendClick() {
				this.$router.push({path:'/diagnosticDetail',query: { id: this.$route.query.id, is_status: this.$route.query.is_status } })
				console.log('单选数据：', this.dxRadio);
				console.log('多选数据：', this.checkList);
			},
			//返回
			exitClick() {
				this.$router.go(-1)
			},
			//切换
			handleClick(e) {
				this.activeIndex = e.index;
        this.$nextTick(()=>{
          this.resetScroll();
        })
				if (e.index>=4) {
					this.allData = [];
					for (let i = 0; i < this.authList.length; i++) {
						if (this.authList[i].title == e.label) {
							this.mobelData = [this.authList[i]];
						}
					}
				} else {
					this.mobelData = [];
					this.initData();
				}
			},
			//获取其他按钮数据
			getAuthBtn() {
				this.$api.companyQuestions({company_id: this.$route.query.id}).then(res=>{
					this.authList = res.data;
					for(let i = 0; i < res.data.length; i++) {
						this.btnFunList.push({title: res.data[i].title, indexs: i, value: res.data[i].title});
					}
				})
				this.$api.areaTreeList().then(res=>{
					this.options = res.data;
				})
			},
			//获取题目
			getTypeText(num) {
				let str = '';
				switch (num){
					case 0:
						str = '单项选择';
						break;
					case 1:
						str = '多项选择';
						break;
					case 2:
						str = '单项填空';
						break;
					case 3:
						str = '多项填空';
						break;
					case 4:
						str = '单选填空';
						break;
					case 5:
						str = '详细地址';
						break;
					case 6:
						str = '日期选择';
						break;
					default:
						break;
				}
				return str;
			},
		},
		watch: {
			companyType(newValue, value) {
				this.disType = newValue;
				this.initData();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.situation-main{margin-top: 18px;background-color: #fff; padding: 30px;
		.title-coantent{margin-bottom: 30px;
			.title-text{position: relative;font-size: 18px;color: #242D33;}
			.title-text::before{position: absolute;content: '';left: -30px;top: 5px;background: #3984F5;width: 4px;height: 20px;}
			.line-title{flex: 1;height: 1px;background: #EDF1F4;margin-left: 10px;}
		}
		.yw-sys-content{margin-top: 10px;
			.ti-list-content{margin-top: 30px;
				.sys-title-text{position: relative;font-size: 14px;font-weight: bold;color: #242D33;margin-left: 10px;
					&:before{position: absolute;content: ''; left: -10px;top: 4px;width: 4px; height: 14px;background: #3984F5;border-radius: 2px;}
				}
				.ti-content{margin-top: 19px;
					.ti-title-text{font-size: 14px;color: #242D33;
						.type-text{font-size: 14px;color: #909DA5;}
					}
					.dx-content{
						.radio-content{margin-top: 19px;}
						.full-text{font-size: 12px;color: #242D33;margin-left: 10px;}
						.radio-img{width: 16px;height: 17px;}
						.d-input-text{width: 400px;height: 34px;border: 1px solid #CEDAE0;box-sizing: border-box;margin-top: 10px;}
						input{padding: 0 15px;}
						
					}
					.duo-x-content{
						.el-checkbox-group{display: flex;flex-direction: column;align-items: flex-start;
							.el-checkbox{margin-top: 19px;}
						}
					}
				}
			}
		}
		.look-btn-content{margin-top: 40px;
			.btn-text{border-radius: 4px;font-size: 14px;text-align: center;line-height: 40px;}
			.look-btn{width: 130px;height: 40px;background: #3984F5;color: #fff;}
			.exit-btn{width: 104px;height: 40px;background: #EEF1F3;color: #6C7880;margin-left: 10px;}
		}
		
		
	}
	::v-deep .el-cascader{height: 34px;line-height: 34px;
		input{height: 34px;line-height: 34px;}
	}
	::v-deep .el-date-picker{height: 34px;line-height: 34px;
		input{height: 34px;line-height: 34px;}
	}
  ::v-deep .quest-scroll {
    height: 520px;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    ;
    }
    .el-scrollbar__bar.is-vertical {
      width: 4px;
    }
    .el-scrollbar__thumb {
      background: #A7B2B8;
    }
  }
</style>