<template>
	<div class="portrait-main">
		<div class="title-coantent flex flex-col-center flex-row-between">
			<span class="title-text">数转画像</span>
			<div class="line-title"></div>
		</div>	
		<div class="top-content flex flex-col-center flex-row-between">
			<div class="con-left">
				<div class="pc-content flex flex-col-center">
					<div class="progress-content">
						<el-progress type="circle" :percentage="msg.diagnose_score" color="#3984F5" text-color="#3984F5" define-back-color="#edf6ff" :width="136" :format="getContent"></el-progress>
						<div class="scro-content">{{Number(msg.diagnose_score).toFixed(1)}}分</div>
					</div>
					<div class="pc-text-content">
						<p>数字化水平评测</p>
						<div class="pc-con-text">
							依据工业和信息化部发布《中小企业数字化水平评测指标（2022年版）》<span class="text-bole">{{msg.company}}</span> ，数字化水平达到<span class="text-red"><span v-if="msg.diagnose_level">{{msg.diagnose_level}}</span><span v-else>无等级</span></span>
						</div>
					</div>
				</div>
				<div class="bx-text-content">
					<p>整体表现：</p>
					<div class="bx-con-text">
						当前企业在<span class="text-red">数字化基础、数字化经营</span>方面较为薄弱，建议加强该板块的内容建设及数字化投入；在<span class="text-blue">数字化管理、数字化成效</span>方面较为突出，建议继续保持，加快实现全流程数字化转型。
					</div>
				</div>
			</div>
			<div class="top-con-right">
				<div id="pcEcharts" style="width: 600px;height:400px;"></div>
			</div>
		</div>
		<num-img-item :msg="allData"></num-img-item>
	</div>
</template>

<script>
	import numImgItem from "@/views/companyImg/components/num-img-item.vue"
	export default{
		name: 'number-portrait-item',
		components: {
			numImgItem
		},
		props: {
			msg: {
				type: Object,
				default: {}
			}
		},
		data(){
			return{
				allData: {}
			}
		},
		mounted() {
			let id = this.$route.query.id;
			this.initData(id);
		},
		methods:{
			initData(id) {
				this.$api.companyPortrayal({company_id: id}).then(res=>{
					this.allData = res.data;
					let nameList = [];
					let valueList = [];
					for (let i = 0; i < res.data.entirety.length; i++) {
						let nameData = {name: res.data.entirety[i].name, max: Number(res.data.entirety[i].value)+20};
						nameList.push(nameData);
						valueList.push(res.data.entirety[i].value);
					}
					
					this.getCanvasScore(nameList, valueList,'#FD5451');
				})
			},
			//评测分内容
			getContent(e) {
				return e+'分';
			},
			//画评测分
			getCanvasScore(indicator, value,color) {
				var myChart = this.$echarts.init(document.getElementById('pcEcharts'));
				let option = {
					color: [color],
          tooltip: {
            trigger: "item",
            backgroundColor: "rgb(255,255,255)",
            borderColor: 'rgb(255,255,255)',
            extraCssText: 'box-shadow: 0 0 15px rgba(0,0,0,0.15);border-radius: 4px;',
            textStyle: {
              color: "#23282d", // 设置字体颜色
              fontSize: 12, // 设置字体大小
            },
            formatter: function(params) {
              var result = '<p>'+ params.name +'</p>';
              params.value.forEach(function(val, index) {
                var circleColor = color;
                // 自定义 tooltip 的内容，包括小圆球的颜色和数据项的名称及其值
                result += '<p style="margin-top: 7px">' + '<span style="display:inline-block;margin-right:5px;border-radius:8px;width:8px;height:8px;background-color:' + circleColor + ';"></span>' + '<span style="color: #3c4248;margin-right: 10px">' + indicator[index].name + '</span>' + '<span style="color: '+circleColor+';font-family: DINR;font-weight: 500">' + val + '</span>' + '</p>';
              });
              return result;
            }
          },
					radar: {
            name: {
              textStyle: {
                color: '#23282d',
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgb(228,231,233)",
                opacity: 1,
                width: 1,
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: "rgb(228,231,233)",
                opacity: 0,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(228,231,233,1)",
                type: "solid",
              },
            },
					    indicator: indicator
					},
					series: [
					    {
							type: 'radar',
							symbol: "circle",
							tooltip: {
							    trigger: 'item'
							},
                itemStyle: {
                  normal: {
                    borderColor: color,
                    borderWidth: 0.8,
                    color:'rgb(255,255,255)'
                  },
                },
                lineStyle: {
                  normal: {
                    color: color,
                    width: 2,
                  },
                },
                areaStyle: {
                  normal: {
                    color: color,
                    opacity: 0.2,
                  },
                },
							data: [
								{
									value: value,
									name: '数字化经营',
									symbol: 'circle',
									areaStyle: {
										color: 'rgba(253, 84, 81, 0.2)'
									}
								}
							]
					    }
					]
				};
				myChart.setOption(option);
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.portrait-main{background-color: #fff;padding: 30px;margin-top: 18px;
		.title-coantent{
			.title-text{position: relative;font-size: 18px;color: #242D33;}
			.title-text::before{position: absolute;content: '';left: -30px;top: 5px;background: #3984F5;width: 4px;height: 20px;}
			.line-title{flex: 1;height: 1px;background: #EDF1F4;margin-left: 10px;}
		}
		.top-content{
			.con-left{
				.pc-content{padding: 77px 0 59px 0;
					.progress-content{position: relative;
						.scro-content{position: absolute;left: 15px;top: 15px;width: 106px;height: 106px;background: linear-gradient(0deg, #EDF6FF 0%, #FFFFFF 100%);
						box-shadow: 0px 5px 15px 0px rgba(57,132,245,0.35);border-radius: 50%;text-align: center;font-size: 30px;color: #3984F5;line-height: 106px;}
					}
					.pc-text-content{margin-left: 50px;
						p{font-size: 20px;color: #242D33;}
						.pc-con-text{margin-top: 24px;font-size: 14px;color: #242D33;max-width: 564px;
							.text-bole{font-weight: bold;}
							.text-red{color: #FD5451;}
						}
					}
				}
				.bx-text-content{width: 884px;height: 120px;background: #FAFCFE;padding: 24px 20px 0 20px;box-sizing: border-box;
					p{font-size: 16px;color: #242D33;}
					.bx-con-text{font-size: 14px;color: #242D33;margin-top: 15px;
						.text-blue{color: #3984F5;}
						.text-red{color: #FD5451;}
					}
				}
			}
			.top-con-right{background-color: #FAFCFE;flex: 1;margin-left: 20px;
				
			}
		}
		
	}
	
</style>